import React from 'react';

const ContactArea = () => {
   return (
      <>
         <section className="contact__area">
            <div className="container-fluid p-0">
               <div className="row g-0">
                  <div className="col-xl-12">
                     <div className="contact__map">
                        <iframe title='contact' src="https://www.google.com.mx/maps/place/Pachuca+de+Soto,+Hgo./@20.083644,-98.8468051,12z/data=!3m1!4b1!4m6!3m5!1s0x85d1a7716f1e038b:0x13315c101496b749!8m2!3d20.1010608!4d-98.7591311!16zL20vMDJjcl9u?entry=ttu"></iframe>
                        <div className="contact__wrapper d-md-flex justify-content-between wow fadeInUp" data-wow-delay=".3s">
                           <div className="contact__info mr-100">
                              <h3>Ponerse en contacto</h3>
                              <ul>
                                 <li>
                                    <h4>Dirección</h4>
                                    <p>Pachuca de Soto, Hidalgo</p>
                                 </li>
                                 <li>
                                    <h4>Llámanos</h4>
                                    <p><a href="tel:(204)-888-234-674">(+52) 771-525-3296</a></p>
                                 </li>
                                 <li>
                                    <h4>Correo electrónico</h4>
                                    <p><a href="promesas.contacto@gmail.com">promesas.contacto@gmail.com</a></p>
                                 </li>
                              </ul>
                           </div>
                           <div className="contact__form">
                              <form >
                                 <input type="text" required placeholder="Tu nombre" />
                                 <input type="email" required placeholder="Correo electrónico" />
                                 <textarea required placeholder="Tu mensaje"></textarea>
                                 <button type="submit" className="z-btn">Enviar Mensaje</button>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ContactArea;